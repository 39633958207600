import React, { useState } from "react";
import {
  Box,
  Button,
  InputAdornment,
  OutlinedInput,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import BackButton from "../../components/common/BackButton";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAxios } from "../../hooks";

const PackageForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const api = useAxios({ autoSnackbar: true });
  const queryClient = useQueryClient();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    credit: "",
    price: "",
    tax: "",
  });

  const handleOnChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  useQuery(
    `company-details_${id}`,
    () => {
      if (id) {
        return api
          .get(`/api/package/i/details/${id}`)
          .then((response) => response.data);
      }
    },
    {
      onSuccess: (data) => {
        if (data) {
          setFormData({
            name: data.name,
            description: data.description,
            credit: data.credit.toString(),
            price: data.price.toString(),
            tax: data.tax.toString(),
          });
        }
      },
    }
  );

  const { mutate: addNew } = useMutation(
    (formData) => {
      return api.post(`/api/package/i/create`, formData);
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(`package-create_${id}`);
        if (res?.data?.statusCode === 200) {
          navigate(-1);
        }
      },
    }
  );

  const { mutate: editPackage } = useMutation(
    (formData) => {
      return api.put(`api/package/i/${id}`, formData);
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(`package-edit_${id}`);
        navigate(-1);
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    const requestData = {
      ...formData,
      credit: formData.credit ? parseInt(formData.credit) : undefined,
      price: formData.price ? parseInt(formData.price) : undefined,
      tax: formData.tax ? parseInt(formData.tax) : undefined,
      is_active: true,
    };

    if (id) {
      editPackage(requestData);
    } else {
      addNew(requestData);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        p: 2,
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
        variant="dense"
        disableGutters={true}
      >
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          <BackButton backFunction={() => navigate(-1)} />
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            {id ? "Edit" : "Add New"} Package
          </Typography>
        </Box>
      </Toolbar>

      <Typography
        variant="subtitle2"
        fontWeight="bold"
        sx={{
          marginTop: "20px",
          marginBottom: "8px",
          color: "secondary.light",
        }}
      >
        Package Name
      </Typography>
      <TextField
        name="name"
        value={formData?.name}
        onChange={handleOnChange}
        label=""
        size="small"
        sx={{
          width: {
            sm: "30%",
            xs: "100%",
          },
          "& .MuiInputBase-root": {
            backgroundColor: "#F0F0F0",
          },
        }}
      />

      <Typography
        variant="subtitle2"
        fontWeight="bold"
        sx={{
          marginTop: "20px",
          marginBottom: "8px",
          color: "secondary.light",
        }}
      >
        Credit
      </Typography>
      <TextField
        name="credit"
        value={formData?.credit}
        onChange={handleOnChange}
        label=""
        size="small"
        sx={{
          width: {
            sm: "30%",
            xs: "100%",
          },
          "& .MuiInputBase-root": {
            backgroundColor: "#F0F0F0",
          },
        }}
      />

      <Typography
        variant="subtitle2"
        fontWeight="bold"
        sx={{
          marginTop: "20px",
          marginBottom: "8px",
          color: "secondary.light",
        }}
      >
        Price
      </Typography>
      <OutlinedInput
        name="price"
        value={formData?.price}
        onChange={handleOnChange}
        type="number"
        endAdornment={<InputAdornment position="end">MMK</InputAdornment>}
        label=""
        size="small"
        sx={{
          width: {
            sm: "30%",
            xs: "100%",
          },
          "&.MuiInputBase-root": {
            backgroundColor: "#F0F0F0",
          },
        }}
      />
      <Typography
        variant="subtitle2"
        fontWeight="bold"
        sx={{
          marginTop: "20px",
          marginBottom: "8px",
          color: "secondary.light",
        }}
      >
        Tax
      </Typography>
      <OutlinedInput
        name="tax"
        value={formData?.tax}
        onChange={handleOnChange}
        type="number"
        endAdornment={<InputAdornment position="end">MMK</InputAdornment>}
        label=""
        size="small"
        sx={{
          width: {
            sm: "30%",
            xs: "100%",
          },
          "&.MuiInputBase-root": {
            backgroundColor: "#F0F0F0",
          },
        }}
      />
      <Typography
        variant="subtitle2"
        fontWeight="bold"
        sx={{
          marginTop: "20px",
          marginBottom: "8px",
          color: "secondary.light",
        }}
      >
        Description
      </Typography>
      <TextField
        name="description"
        value={formData?.description}
        onChange={handleOnChange}
        multiline
        rows={4}
        size="small"
        sx={{
          width: "100%",
          "& .MuiInputBase-root": {
            backgroundColor: "#F0F0F0",
          },
        }}
        InputProps={{
          placeholder: "Please enter description for package.",
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          mt: 4,
          justifyContent: { xs: "center", lg: "start" },
        }}
      >
        <Button
          variant="contained"
          type="submit"
          sx={{
            width: { lg: 190, md: 190, sm: 140, xs: 140 },
            height: 35,
            boxShadow: "none",
          }}
        >
          {id ? "Edit" : "Create"}
        </Button>
        <Button
          color="warning"
          variant="outlined"
          sx={{ width: { lg: 190, md: 190, sm: 140, xs: 140 }, height: 35 }}
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default PackageForm;
