import React from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import { useState } from "react";
import Switch from "@mui/material/Switch";
// import { ChangePasswordDialog } from "../../components/profile/";
import { ChangePasswordDialog } from "../../components/profile";
import { useQuery } from "react-query";
import useAxios from "../../hooks/useAxios";
import { getDateTime } from "../../utilities/getDateTime";
import { useMutation } from "react-query";
const Me = () => {
  const api = useAxios({ autoSnackbar: true });
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    is_active: true,
  });

  const { mutate } = useMutation((userData) => {
    return api.put("/api/user/", userData);
  });

  const handleOnSave = () => {
    mutate(userData);
  };

  const handleDialogOnClick = () => {
    setOpen(!open);
  };

  const handleSwitchChange = (event) => {
    setUserData({ ...userData, is_active: event.target.checked });
  };

  const handleOnChange = (event) => {
    setUserData({ ...userData, [event.target.name]: event.target.value });
  };

  useQuery("user-me", async () => {
    const res = await api.get("/api/user/me");
    setUserData(res.data);
  });

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        borderRadius: "0.8rem",
        backgroundColor: "#fff",
        px: { xs: 2, lg: 5 },
        py: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            fontSize: "1.3rem",
          }}
        >
          Profile&nbsp;
          <Typography
            sx={{
              color: "secondary.light",
            }}
            variant="caption"
          >
            Internal
          </Typography>
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        <Typography
          variant="subtitle2"
          fontWeight="bold"
          sx={{
            marginTop: "20px",
            marginBottom: "8px",
            color: "secondary.light",
          }}
        >
          Name
        </Typography>
        <TextField
          size="small"
          id="outlined-uncontrolled"
          name="name"
          value={userData?.name}
          onChange={handleOnChange}
          sx={{
            width: "100%",
            border: "none",
            borderRadius: 1,
            "& .MuiInputBase-root": {
              backgroundColor: "#F0F0F0",
              borderRadius: 1,
              outline: "none",
              "&:hover": {
                backgroundColor: "#F0F0F0",
              },
            },
          }}
        />

        <Typography
          variant="subtitle2"
          fontWeight="bold"
          sx={{
            marginTop: "20px",
            marginBottom: "8px",
            color: "secondary.light",
          }}
        >
          Email
        </Typography>
        <TextField
          size="small"
          value={userData?.email}
          disabled
          name="headquarter_country"
          sx={{
            width: "100%",
            border: "none",
            borderRadius: 1,
            "& .MuiInputBase-root": {
              backgroundColor: "#F0F0F0",
              borderRadius: 1,
              outline: "none",
              "&:hover": {
                backgroundColor: "#F0F0F0",
              },
            },
          }}
        />
        <Typography
          variant="subtitle2"
          fontWeight="bold"
          sx={{
            marginTop: "20px",
            marginBottom: "8px",
            color: "secondary.light",
          }}
        >
          Status{" "}
          <Switch onChange={handleSwitchChange} checked={userData?.is_active} />
        </Typography>

        <Typography
          variant="subtitle2"
          sx={{
            marginBottom: "8px",
            color: "secondary.light",
          }}
        >
          Registered at {getDateTime(userData?.created_at)}
        </Typography>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: { xs: "center", md: "flex-start" },
            gap: 2,
          }}
        >
          <Button
            size="small"
            disableElevation
            variant="contained"
            onClick={handleOnSave}
          >
            Save
          </Button>
          <Button
            size="small"
            onClick={handleDialogOnClick}
            disableElevation
            variant="outlined"
            color="error"
          >
            Change Password
          </Button>
        </Box>

        <ChangePasswordDialog
          open={open}
          handleDialogOnClick={handleDialogOnClick}
        />
      </Box>
    </Box>
  );
};

export default Me;
