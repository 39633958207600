import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import CreateNewUsers from "./CreateNewUsers";
import UsersList from "./UsersList";

const Users = () => {
  return (
    <Routes>
      <Route path="" element={<UsersList />} exact />
      {/* <Route path='details/:id' element={<UsersDetails />} /> */}
      <Route path="*" element={<Navigate to={""} replace />} />
      <Route path="add" element={<CreateNewUsers />} />
      <Route path="edit/:id" element={<CreateNewUsers />} />
    </Routes>
  );
};

export default Users;
