import { useState } from "react";
import { Box, Button } from "@mui/material";
import { Tab, Tabs } from "@mui/material";
import TabPanel from "../../components/common/TabPanel";
import { useNavigate } from "react-router-dom";
// import { ActivePackages, InactivePackages } from "../../components/packages";
import {
  ActiveAdvertisements,
  InactiveAdvertisements,
} from "../../components/advertisement";

const AdvertisementsList = () => {
  const navigate = useNavigate();

  const [tab, setTab] = useState(0);
  const [selection, setSelection] = useState([]);

  const handleChange = (event, newTab) => {
    setTab(newTab);
  };

  return (
    <Box
      sx={{
        height: "90%",
        width: "100%",
        px: { xs: "1rem", lg: "2rem" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Tabs
          value={tab}
          onChange={handleChange}
          variant="scrollable"
          allowScrollButtonsMobile
          sx={{
            minHeight: "24px",
            "& .MuiTab-root": { minHeight: "24px", padding: "4px" },
            pt: 2,
          }}
        >
          <Tab label="Active" />
          <Tab label="Inactive" />
        </Tabs>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            sx={{
              display: selection.length === 1 ? "none" : "flex",
              fontWeight: 700,
            }}
            onClick={() => navigate("add")}
          >
            New
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{
              display: selection.length === 1 ? "flex" : "none",
              fontWeight: 700,
            }}
            onClick={() => navigate("details/" + selection[0])}
          >
            Details
          </Button>
        </Box>
      </Box>
      <TabPanel value={tab} index={0}>
        <ActiveAdvertisements handleOnSelecting={setSelection} />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <InactiveAdvertisements handleOnSelecting={setSelection} />
      </TabPanel>
    </Box>
  );
};

export default AdvertisementsList;
