import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { withUser } from "../recoil/auth";
import UserRoles from "../utilities/userRoles";
import { useNavigate } from "react-router-dom";

const FeaturesSegegration = ({
  isPublic = false,
  roles = [],
  fallbackRoute = "/dashboard",
  children,
}) => {
  const user = useRecoilValue(withUser);
  const navigate = useNavigate();

  const [showChildren, setShowChildren] = useState(false);

  useEffect(() => {
    const hasPermission = [UserRoles.ADMINISTRATOR, ...roles].includes(
      user.role
    );

    if (hasPermission || isPublic) {
      setShowChildren(true);
    } else {
      navigate(fallbackRoute, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return <>{showChildren && children}</>;
};

export default FeaturesSegegration;
