import React, { useCallback, useMemo, useState } from "react";
import { useAxios } from "../../hooks";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Button, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ComfirmDialog from "../common/ConfirmDialog";
import { getDateTime } from "../../utilities/getDateTime";

const ActivePackages = ({ handleOnSelecting }) => {
  const api = useAxios({ autoSnackbar: true });
  const queryClient = useQueryClient();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({ company_id: "", action: "" });

  const handleOpen = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const { data: res, isLoading } = useQuery(
    `package-list_active_page=${page}_rowsPerPage=${pageSize}`,
    () => {
      return api.get(
        `/api/package/i/list/true/p?page=${page}&rowsPerPage=${pageSize}`
      );
    }
  );

  const { mutate } = useMutation(
    () => {
      return api.put(
        `/api/package/i/status/${data.package_id}?status=${data.action}`
      );
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(
          `package-list_active_page=${page}_rowsPerPage=${pageSize}`
        );
      },
    }
  );

  const columns = useMemo(
    () => [
      { field: "id", headerName: "ID", minWidth: 90, flex: 0.5 },
      { field: "name", headerName: "Package Name", minWidth: 100, flex: 1 },
      { field: "credit", headerName: "Credit", minWidth: 100, flex: 1 },
      { field: "price", headerName: "Price", minWidth: 100, flex: 1 },
      { field: "tax", headerName: "Tax", minWidth: 100, flex: 1 },
      {
        field: "created_at",
        headerName: "Created At",
        minWidth: 100,
        flex: 1,
        valueFormatter: (params) => getDateTime(params.value),
      },
      {
        field: "action",
        headerName: "Action",
        sortable: false,
        minWidth: 100,
        flex: 1,
        renderCell: (params) => (
          <Button
            variant="outlined"
            color="error"
            size="small"
            onClick={() => {
              setData({
                package_id: params.id,
                action: !params.row.is_active,
              });
              handleOpen();
            }}
          >
            <Typography sx={{ fontSize: "11px" }}>Deactivate</Typography>
          </Button>
        ),
      },
    ],
    [setData, handleOpen]
  );

  return (
    <>
      <DataGrid
        columns={columns}
        rowCount={res?.data?.total ?? 0}
        rows={res?.data?.rows ?? []}
        loading={isLoading}
        pagination
        paginationMode="server"
        checkboxSelection
        onSelectionModelChange={handleOnSelecting}
        page={page}
        pageSize={pageSize}
        onPageChange={(v) => setPage(v)}
        onPageSizeChange={(v) => setPageSize(v)}
      />
      <ComfirmDialog
        open={open}
        handleClose={handleOpen}
        handleClickOpen={handleOpen}
        handleOnConfirm={mutate}
      />
    </>
  );
};

export default ActivePackages;
