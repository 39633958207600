import { useState } from "react";
import { Box, Button } from "@mui/material";
import { Tab, Tabs } from "@mui/material";
import TabPanel from "../../components/common/TabPanel";
import {
  AppliedList,
  AcceptedList,
  ActiveList,
  InactiveList,
  RejectedList,
} from "../../components/brandname";
import { useNavigate } from "react-router-dom";

const BrandnameList = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const [selection, setSelection] = useState([]);

  const handleChange = (event, newTab) => {
    setTab(newTab);
  };

  return (
    <Box
      sx={{
        height: "90%",
        width: "100%",
        px: { xs: "1rem", lg: "2rem" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Tabs
          value={tab}
          onChange={handleChange}
          variant="scrollable"
          allowScrollButtonsMobile
          sx={{
            minHeight: "24px",
            "& .MuiTab-root": { minHeight: "24px", padding: "4px" },
            pt: 2,
          }}
        >
          <Tab label="Applied" />
          <Tab label="Accepted" />
          <Tab label="Active" />
          <Tab label="Inactive" />
          <Tab label="Reject" />
        </Tabs>
        <Box>
          <Button
            variant="contained"
            size="small"
            sx={{
              display: selection.length === 1 ? "block" : "none",
            }}
            onClick={() => navigate("details/" + selection[0])}
          >
            Details
          </Button>
        </Box>
      </Box>
      <TabPanel value={tab} index={0}>
        <AppliedList handleOnSelecting={setSelection} />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <AcceptedList handleOnSelecting={setSelection} />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <ActiveList handleOnSelecting={setSelection} />
      </TabPanel>
      <TabPanel value={tab} index={3}>
        <InactiveList handleOnSelecting={setSelection} />
      </TabPanel>
      <TabPanel value={tab} index={4}>
        <RejectedList handleOnSelecting={setSelection} />
      </TabPanel>
    </Box>
  );
};

export default BrandnameList;
