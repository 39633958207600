import Login from "./containers/Login";
import Dashboard from "./containers/Dashboard";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import PrivateRoute from "./hocs/PrivateRoute";
import { CustomSnackbar } from "./hocs/CustomeSnackbar";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <CustomSnackbar>
        <Router>
          <Routes>
            <Route path='login' element={<Login />} />
            <Route
              path='dashboard/*'
              element={<PrivateRoute component={Dashboard} />}
            />
            <Route path='*' element={<Navigate to={"dashboard"} replace />} />
          </Routes>
        </Router>
      </CustomSnackbar>
    </QueryClientProvider>
  );
}

export default App;
