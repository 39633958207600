import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { withAlert } from "../../recoil/snackbar";
import { useSetRecoilState } from "recoil";
import { useMutation } from "react-query";
import useAxios from "../../hooks/useAxios";

const ChangePasswordDialog = ({ open, handleDialogOnClick }) => {
  const openAlert = useSetRecoilState(withAlert);
  const api = useAxios({ autoSnackbar: true });
  const [data, setData] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const { mutate } = useMutation(
    (data) => {
      return api.put("/api/user/changePassword", data);
    },
    {
      onSuccess: (res) => {
        handleDialogOnClick();
      },
    }
  );

  const handleCheckPasswords = () => {
    if (data.new_password === data.confirm_password) {
      mutate(data);
      setData({
        old_password: "",
        new_password: "",
        confirm_password: "",
      });
    } else {
      openAlert({
        status: 400,
        detail: "Passwords do not match.",
      });
    }
  };

  const handleOnChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  return (
    <Dialog
      open={open}
      onClose={handleDialogOnClick}
      PaperProps={{
        style: { borderRadius: 15 },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {"Change Password"}
        <IconButton
          aria-label="close"
          onClick={handleDialogOnClick}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          height: "100%",
          m: 2,
          borderRadius: "0.8rem",
          backgroundColor: "#fff",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexDirection: "column",
          "@media (max-width: 959px)": {
            height: "auto",
            maxHeight: "80vh",
            overflowY: "auto",
            borderRadius: "0.8rem",
          },
        }}
      >
        <Typography
          variant="subtitle2"
          fontWeight="bold"
          sx={{
            color: "secondary.light",
          }}
        >
          Old Password
        </Typography>
        <TextField
          name="old_password"
          size="small"
          label="Password"
          type="password"
          value={data.old_password}
          onChange={handleOnChange}
          fullWidth
          margin="normal"
          sx={{
            border: "none",
            borderRadius: 1,
            "& .MuiInputBase-root": {
              backgroundColor: "#F0F0F0",
              borderRadius: 1,
              outline: "none",
              "&:hover": {
                backgroundColor: "#F0F0F0",
              },
            },
          }}
        />
        <Typography
          variant="subtitle2"
          fontWeight="bold"
          sx={{
            color: "secondary.light",
          }}
        >
          New Password
        </Typography>

        {/* New Password */}
        <TextField
          name="new_password"
          size="small"
          label="Password"
          type="password"
          value={data.new_password}
          onChange={handleOnChange}
          fullWidth
          margin="normal"
          sx={{
            border: "none",
            borderRadius: 1,
            "& .MuiInputBase-root": {
              backgroundColor: "#F0F0F0",
              borderRadius: 1,
              outline: "none",
              "&:hover": {
                backgroundColor: "#F0F0F0",
              },
            },
          }}
        />

        {/* Confirm Password */}
        <TextField
          name="confirm_password"
          size="small"
          label="Confirm Password"
          type="password"
          value={data.confirm_password}
          onChange={handleOnChange}
          fullWidth
          margin="dense"
          sx={{
            border: "none",
            borderRadius: 1,
            "& .MuiInputBase-root": {
              backgroundColor: "#F0F0F0",
              borderRadius: 1,
              outline: "none",
              "&:hover": {
                backgroundColor: "#F0F0F0",
              },
            },
          }}
        />

        <Button
          sx={{ mt: 1 }}
          fullWidth
          disableElevation
          size="small"
          onClick={handleCheckPasswords}
          variant="contained"
        >
          Save
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default ChangePasswordDialog;
