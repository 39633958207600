import { Navigate, Route, Routes } from "react-router-dom";
// import { OrderList } from ".";
import OrderList from "./OrderList";

const Order = () => {
  return (
    <Routes>
      <Route path="" element={<OrderList />} />
      {/* <Route path="details/:id" element={<OrderDetails />} /> */}
      <Route path="*" element={<Navigate to="" replace />} />
    </Routes>
  );
};

export default Order;
