import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import CreateNewPhoneGroup from "./CreateNewPhoneGroup";
import PhonegroupDetails from "./PhonegroupDetails";
import PhonegroupList from "./PhonegroupList";

const Phonegroup = () => {
  return (
    <Routes>
      <Route path="" element={<PhonegroupList />} />
      <Route path="details/:id" element={<PhonegroupDetails />} />
      <Route path="add" element={<CreateNewPhoneGroup />} />
      <Route path="edit/:id" element={<CreateNewPhoneGroup />} />
      <Route path="*" element={<Navigate to="" replace />} />
    </Routes>
  );
};

export default Phonegroup;
