import React, { useState } from "react";
import { Box, Button, TextField, Toolbar, Typography } from "@mui/material";
import BackButton from "../../components/common/BackButton";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAxios } from "../../hooks";
import { DragAndDropPicUpload } from "../../components/advertisement";

const AdvertisementForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const api = useAxios({ autoSnackbar: true });
  const queryClient = useQueryClient();
  const [formData, setFormData] = useState({
    subject: "",
    image_filename: null,
    action: "",
  });

  const handleOnChange = (e) => {
    if (e.target.name === "image_filename") {
      setFormData({
        ...formData,
        image_filename: e.target.files[0],
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  useQuery(
    `annoucement-details_${id}`,
    () => {
      if (id) {
        return api
          .get(`/api/advertisement/i/${id}`)
          .then((response) => response.data);
      }
    },
    {
      onSuccess: (data) => {
        if (data) {
          setFormData({
            subject: data.subject,
            image_filename: data.image_filename,
            action: data.action,
          });
        }
      },
    }
  );

  const { mutate: addNew } = useMutation(
    (formData) => {
      return api.postForm(`/api/advertisement/i`, formData);
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(`advetisement-list_active`);
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    let requestData = { ...formData };
    addNew(requestData);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: {
          xs: "100%",
          sm: "60%",
          md: "30%",
        },
        p: 2,
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
        variant="dense"
        disableGutters={true}
      >
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          <BackButton backFunction={() => navigate(-1)} />
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            {id ? "Edit" : "Add New"} Advertisement
          </Typography>
        </Box>
      </Toolbar>

      <Typography
        variant="subtitle2"
        fontWeight="bold"
        sx={{
          marginTop: "20px",
          marginBottom: "8px",
          color: "secondary.light",
        }}
      >
        Subject
      </Typography>
      <TextField
        name="subject"
        value={formData?.subject}
        onChange={handleOnChange}
        label=""
        size="small"
        sx={{
          width: "100%",
          "& .MuiInputBase-root": {
            backgroundColor: "#F0F0F0",
          },
        }}
      />
      <Box>
        <Typography
          variant="subtitle2"
          fontWeight="bold"
          sx={{
            marginTop: "20px",
            marginBottom: "8px",
            color: "secondary.light",
          }}
        >
          Advertisement Picture
        </Typography>
        {/* {formData?.image_filename && (
          <img
            src={`${baseURL}/${formData.image_filename}`}
            alt="Advertisement Pic"
            style={{ width: "100%", height: "auto" }}
          />
        )} */}
        {id ? null : (
          <DragAndDropPicUpload data={formData} setData={setFormData} />
        )}
      </Box>
      <Typography
        variant="subtitle2"
        fontWeight="bold"
        sx={{
          marginTop: "20px",
          marginBottom: "8px",
          color: "secondary.light",
        }}
      >
        Action (optional)
      </Typography>
      <TextField
        name="action"
        value={formData?.action}
        onChange={handleOnChange}
        label=""
        size="small"
        sx={{
          width: "100%",
          "& .MuiInputBase-root": {
            backgroundColor: "#F0F0F0",
          },
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          mt: 2,
          width: "100%",
          justifyContent: { xs: "center", lg: "space-between" },
        }}
      >
        <Button
          variant="contained"
          type="submit"
          sx={{
            flex: 1,
            height: 35,
            boxShadow: "none",
          }}
          onClick={() => navigate(-1)}
        >
          {id ? "Edit" : "Create"}
        </Button>
        <Button
          color="error"
          variant="outlined"
          fullWidth
          sx={{
            flex: 1,
            height: 35,
          }}
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default AdvertisementForm;
