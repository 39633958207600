import React from "react";
import {
  Box,
  Typography,
  Button,
  Select,
  FormControl,
  MenuItem,
  TextField,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAxios } from "../../hooks";
import copy from "copy-to-clipboard";
import { withAlert } from "../../recoil/snackbar";
import { useSetRecoilState } from "recoil";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useState } from "react";

const ConfirmOrderForm = ({
  handleClose,
  open,
  orderId,
  companyName,
  orderNumber,
  packagePrice,
}) => {
  const api = useAxios({ autoSnackbar: true });
  const queryClient = useQueryClient();
  const openAlert = useSetRecoilState(withAlert);

  const [formData, setFormData] = useState({
    payment_type: "CASH",
    payment_method: "",
    note: "",
    amount: "",
  });

  const [page] = useState(0);
  const [pageSize] = useState(25);

  const { data: res } = useQuery(`order-details_${orderId}`, () => {
    if (orderId) {
      return api.get(`/api/order/i/details/${orderId}`);
    }
    return null;
  });

  useQuery(`order-list_pending_page=${page}_rowsPerPage=${pageSize}`, () => {
    return api.get(
      `/api/order/i/PENDING/p?page=${page}&rowsPerPage=${pageSize}`
    );
  });

  let totalAmount = 0;
  if (res && res.data.payment) {
    totalAmount = res.data.payment.reduce((total, payment) => {
      return total + payment.amount;
    }, 0);
  }

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;

    if (name === "amount") {
      updatedValue = value ? parseInt(value) : "";
    }
    setFormData({ ...formData, [name]: updatedValue });
  };

  const { mutate } = useMutation(
    () => {
      return api.post(`/api/order/i/payment/${orderId}`, formData);
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(`order-details_${orderId}`);
        queryClient.invalidateQueries(
          `order-list_pending_page=${page}_rowsPerPage=${pageSize}`
        );
        handleClose();
      },
    }
  );

  const handleAddClick = (e) => {
    e.preventDefault();
    mutate(formData, {
      onSuccess: () => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          amount: "",
        }));
        handleClose();
      },
    });
  };

  const remainingAmount = packagePrice - totalAmount - formData.amount;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      component="form"
      PaperProps={{
        style: { borderRadius: 15 },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        Payment Form
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          height: "100%",
          borderRadius: "0.8rem",
          backgroundColor: "#fff",
          m: { md: 2, xs: 1 },
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexDirection: "column",
          "@media (max-width: 959px)": {
            height: "auto",
            maxHeight: "80vh",
            overflowY: "auto",
            borderRadius: "0.8rem",
          },
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mb: 3,
              mt: 2,
              gap: 1,
            }}
          >
            <Typography
              fontWeight="light"
              fontSize="0.9rem"
              sx={{ color: "secondary.light" }}
            >
              Order ID -
            </Typography>
            <Typography
              fontSize="1rem"
              fontWeight="bold"
              sx={{ color: "secondary.light" }}
            >
              {orderId}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mb: 3,
              mt: 2,
              gap: 1,
            }}
          >
            <Typography
              fontWeight="light"
              fontSize="0.9rem"
              sx={{ color: "secondary.light" }}
            >
              Company Name -
            </Typography>
            <Typography
              fontSize="1rem"
              fontWeight="bold"
              sx={{ color: "secondary.light" }}
            >
              {companyName}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mb: 3,
              mt: 2,
              gap: 1,
            }}
          >
            <Typography
              fontWeight="light"
              fontSize="0.9rem"
              sx={{ color: "secondary.light" }}
            >
              Order Number -
            </Typography>
            <Typography
              fontSize="1rem"
              fontWeight="bold"
              sx={{ color: "secondary.light" }}
            >
              {orderNumber}
            </Typography>
            <IconButton
              size="small"
              sx={{
                width: "1rem",
                padding: 0,
              }}
              onClick={() => {
                copy(orderNumber);
                openAlert({
                  status: 200,
                  detail: "Copied to clipboard",
                });
              }}
            >
              <ContentCopyIcon sx={{ width: "1rem" }} />
            </IconButton>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
            <Typography
              fontWeight="light"
              fontSize="0.9rem"
              sx={{ color: "secondary.light" }}
            >
              Payment Type
            </Typography>
            <FormControl sx={{ width: "100%" }}>
              <Select
                size="small"
                name="payment_type"
                value={formData.payment_type}
                onChange={handleOnChange}
                sx={{
                  border: "none",
                  borderRadius: 1,
                  "& .MuiInputBase-root": {
                    backgroundColor: "#F0F0F0",
                    borderRadius: 1,
                    outline: "none",
                    "&:hover": { backgroundColor: "#F0F0F0" },
                    color: "#555",
                  },
                  backgroundColor: "#F0F0F0",
                }}
              >
                <MenuItem value="CASH">CASH</MenuItem>
                <MenuItem value="BANKING">BANKING</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mb: 2,
              width: "100%",
            }}
          >
            <Typography
              fontWeight="light"
              fontSize="0.9rem"
              sx={{ color: "secondary.light" }}
            >
              Payment Method
            </Typography>
            <TextField
              size="small"
              fullWidth
              placeholder="Payment Method"
              name="payment_method"
              onChange={handleOnChange}
              sx={{
                border: "none",
                borderRadius: 1,
                "& .MuiInputBase-root": {
                  backgroundColor: "#F0F0F0",
                  borderRadius: 1,
                  outline: "none",
                  "&:hover": { backgroundColor: "#F0F0F0" },
                },
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mb: 2,
              width: "100%",
            }}
          >
            <Typography
              fontWeight="light"
              fontSize="0.9rem"
              sx={{ color: "secondary.light" }}
            >
              Amount
              {remainingAmount >= 0 && (
                <Typography variant="caption" fontSize="0.8rem">
                  &nbsp;({remainingAmount} Remaining Amount)
                </Typography>
              )}
            </Typography>
            <OutlinedInput
              name="amount"
              value={formData.amount}
              onChange={handleOnChange}
              type="number"
              endAdornment={<InputAdornment position="end">MMK</InputAdornment>}
              label=""
              size="small"
              sx={{
                "&.MuiInputBase-root": {
                  backgroundColor: "#F0F0F0",
                },
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mb: 2,
              width: "100%",
            }}
          >
            <Typography
              fontWeight="light"
              fontSize="0.9rem"
              sx={{ color: "secondary.light" }}
            >
              Note
            </Typography>
            <TextField
              size="small"
              fullWidth
              multiline
              rows={4}
              name="note"
              onChange={handleOnChange}
              sx={{
                border: "none",
                borderRadius: 1,
                "& .MuiInputBase-root": {
                  backgroundColor: "#F0F0F0",
                  borderRadius: 1,
                  outline: "none",
                  "&:hover": { backgroundColor: "#F0F0F0" },
                },
              }}
            />
          </Box>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  width: { lg: 190, md: 190, sm: 140, xs: 90 },
                  height: 35,
                  boxShadow: "none",
                }}
                onClick={handleAddClick}
              >
                Submit
              </Button>
              <Button
                variant="outlined"
                sx={{
                  width: { lg: 190, md: 190, sm: 140, xs: 90 },
                  height: 35,
                }}
                onClick={handleClose}
                autoFocus
              >
                Cancel
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmOrderForm;
