import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import BrandnameList from './BrandnameList';
import BrandnameDetails from "./BrandnameDetails";

const Brandname = () => {
  return (
    <Routes>
      <Route path='' element={<BrandnameList />} exact />
      <Route path='details/:id' element={<BrandnameDetails />} />
      <Route path='*' element={<Navigate to={""} replace />} />
    </Routes>
  );
};

export default Brandname;
