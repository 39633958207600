import React, { useCallback, useMemo, useState } from "react";
import { useAxios } from "../../hooks";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ComfirmDialog from "../common/ConfirmDialog";
import { getDateTime } from "../../utilities/getDateTime";
import NoRowOverlay from "../common/NoRowOverlay";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Tooltip } from "@mui/material";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import DeleteIcon from "@mui/icons-material/Delete";

const ActivePhonegroup = ({ handleOnSelecting }) => {
  const navigate = useNavigate();
  const api = useAxios({ autoSnackbar: true });
  const queryClient = useQueryClient();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [open, setOpen] = useState(false);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [phoneToDeleteId, setPhoneToDeleteId] = useState(null);

  const handleOpen = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const { data: res, isLoading } = useQuery(
    `phonegroup-list_active_page=${page}_rowsPerPage=${pageSize}`,
    () => {
      return api.get(
        `/api/phonegroup/i/p?page=${page}&rowsPerPage=${pageSize}`
      );
    }
  );

  const { mutate: deleteNumber } = useMutation(
    (idToDelete) => {
      return api.delete(`/api/phonegroup/i/${idToDelete}/permanent`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          `phonegroup-list_active_page=${page}_rowsPerPage=${pageSize}`
        );
      },
    }
  );

  const handleDeleteConfirmation = () => {
    handleDeleteNumber(phoneToDeleteId);
  };

  const handleDeleteNumber = (idToDelete) => {
    deleteNumber(idToDelete);
  };

  const handleOpenDeleteDialog = useCallback(() => {
    setOpenDeleteDialog((prev) => !prev);
  }, []);

  const columns = useMemo(
    () => [
      { field: "id", headerName: "ID", minWidth: 90, flex: 0.5 },
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        minWidth: 100,
      },
      {
        field: "description",
        headerName: "Description",
        flex: 1,
        minWidth: 150,
      },
      {
        field: "created_at",
        headerName: "Created At",
        flex: 1,
        minWidth: 100,
        valueFormatter: (params) => getDateTime(params.value),
      },
      {
        field: "created_by",
        headerName: "Created By",
        flex: 1,
        minWidth: 100,
      },
      {
        field: "action",
        headerName: "Action",
        flex: 1,
        minWidth: 100,
        renderCell: (params) => (
          <Box
            sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
          >
            <Tooltip title="Remove">
              <IconButton
                size="small"
                onClick={() => {
                  setPhoneToDeleteId(params.row.id);
                  handleOpenDeleteDialog();
                }}
              >
                <DeleteIcon
                  sx={{
                    cursor: "pointer",
                    "&:hover": { color: "red" },
                  }}
                />
              </IconButton>
            </Tooltip>

            <Tooltip title="Details">
              <IconButton
                size="small"
                onClick={() => navigate(`details/${params.id}`)}
              >
                <InfoRoundedIcon
                  sx={{
                    cursor: "pointer",
                    "&:hover": { color: "primary.main" },
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );
  return (
    <>
      <DataGrid
        columns={columns}
        rowCount={res?.data?.total ?? 0}
        rows={res?.data?.rows ?? []}
        loading={isLoading}
        pagination
        paginationMode="server"
        checkboxSelection
        onSelectionModelChange={handleOnSelecting}
        page={page}
        pageSize={pageSize}
        onPageChange={(v) => setPage(v)}
        onPageSizeChange={(v) => setPageSize(v)}
        components={{
          Toolbar: GridToolbar,
          NoRowsOverlay: NoRowOverlay,
        }}
      />
      <ComfirmDialog
        open={openDeleteDialog}
        handleClose={handleOpenDeleteDialog}
        handleOnConfirm={handleDeleteConfirmation}
      />
    </>
  );
};

export default ActivePhonegroup;
