import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function RejectDialog({
  open,
  data,
  handleClose,
  handleOnRejectConfirm,
  handleReasonChange
}) {
  
  return (
    <div>
      <Dialog open={open} onClose={handleClose} PaperProps={{ sx: {
        borderRadius: "10px",
        width: "90%",
      } }}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Why you want to reject this brandname?
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="reason"
            label="Reason"
            type="text"
            fullWidth
            variant="standard"
            multiline
            rows={4}
            onChange={handleReasonChange}
            value={data.reason}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleOnRejectConfirm();
              handleClose();
            }}
            autoFocus
          >
            Reject
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
