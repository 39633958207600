import { Navigate, Route, Routes } from "react-router-dom";
import { CompanyList, CompanyDetails } from ".";

const Company = () => {
  return (
    <Routes>
      <Route path='' element={<CompanyList />} />
      <Route path='details/:id' element={<CompanyDetails />} />
      <Route path='*' element={<Navigate to='' replace />} />
    </Routes>
  );
};

export default Company;