import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { DialogContent, useMediaQuery } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useMemo } from "react";
import { useAxios } from "../../hooks";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getDateTime } from "../../utilities/getDateTime";
import { useState } from "react";
import { useParams } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddPhonegroupDialog({ open, handleClose }) {
  const isSmallToMediumScreen = useMediaQuery((theme) =>
    theme.breakpoints.down("md")
  );
  const { id } = useParams();

  const api = useAxios({ autoSnackbar: true });
  const queryClient = useQueryClient();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const [selectedPhonegroup, setSelectedPhonegroup] = useState([]);

  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);

  const { data: res, isLoading } = useQuery(
    `phonegroup-list_add_page=${page}_rowsPerPage=${pageSize}`,
    () => {
      return api.get(
        `/api/phonegroup/i/${id}/p?page=${page}&rowsPerPage=${pageSize}`
      );
    }
  );

  const { mutate } = useMutation(
    () => {
      return api.post(`/api/phonegroup/i/add/${id}`, {
        list: selectedPhonegroup,
      });
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(
          `phonegroup-list_add_page=${page}_rowsPerPage=${pageSize}`
        );
        queryClient.invalidateQueries(`phonegroup-list_active_${id}`);
      },
    }
  );

  const handleSelectionModelChange = (selectionModel) => {
    setSelectedPhonegroup(selectionModel);
    setIsAddButtonDisabled(selectionModel.length === 0);
  };

  const handleAddClick = () => {
    if (setSelectedPhonegroup.length > 0) {
      mutate();
      handleClose();
    }
  };

  const columns = useMemo(
    () => [
      { field: "id", headerName: "ID", minWidth: 90 },
      {
        field: "name",
        headerName: "Name",
        flex: 0.5,
        minWidth: 100,
      },
      {
        field: "description",
        headerName: "Description",
        flex: 1.2,
        minWidth: 150,
      },
      {
        field: "created_at",
        headerName: "Created At",
        flex: 0.5,
        minWidth: 100,
        valueFormatter: (params) => getDateTime(params.value),
      },
    ],
    []
  );

  return (
    <Dialog
      open={open}
      fullScreen={isSmallToMediumScreen}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{
        sx: { height: { md: 400 }, minWidth: { lg: 600, md: 600 } },
      }}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Phone Groups
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={handleAddClick}
            disabled={isAddButtonDisabled}
          >
            Add
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <DataGrid
          columns={columns}
          rowCount={res?.data?.total ?? 0}
          rows={res?.data?.rows ?? []}
          loading={isLoading}
          pagination
          paginationMode="server"
          checkboxSelection
          onSelectionModelChange={handleSelectionModelChange}
          page={page}
          pageSize={pageSize}
          onPageChange={(v) => setPage(v)}
          onPageSizeChange={(v) => setPageSize(v)}
        />
      </DialogContent>
    </Dialog>
  );
}
