import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Toolbar,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import BackButton from "../../components/common/BackButton";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAxios } from "../../hooks";
import ResetPasswordDialog from "../../components/account/ResetPasswordDialog";

const CreateNewUsers = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const api = useAxios({ autoSnackbar: true });
  const queryClient = useQueryClient();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    role: "",
  });
  const [data, setData] = useState({ name: "", role: "" });

  const [open, setOpen] = useState(false);
  const handleDialogOnClick = () => {
    setOpen(!open);
  };

  const handleOnChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  useQuery(
    `user-details_${id}`,
    () => {
      if (id) {
        return api
          .get(`/api/user/i/details/${id}`)
          .then((response) => response.data);
      }
    },
    {
      onSuccess: (data) => {
        if (data) {
          setFormData({
            name: data.name,
            email: data.email,
            password: data.password,
            role: data.role,
          });
        }
      },
    }
  );

  const { mutate: addNew } = useMutation(
    (formData) => {
      return api.post(`/api/user/i/create`, formData);
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(`internal-user_list`);
      },
    }
  );

  const { mutate: editUser } = useMutation(
    (formData) => {
      return api.put(`api/user/i/${id}`, formData);
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(`internal-user_list`);
        setData({ name: formData.name, role: formData.role });
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    let requestData = { ...formData };

    if (id) {
      delete requestData.is_active;
      editUser(requestData);
    } else {
      requestData.is_active = true;
      addNew(requestData);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: {
          xs: "100%",
          sm: "60%",
          md: "30%",
        },
        p: 2,
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
        variant="dense"
        disableGutters={true}
      >
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          <BackButton backFunction={() => navigate(-1)} />
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            {id ? "Edit" : "Add New"} User
          </Typography>
        </Box>
      </Toolbar>

      <Typography
        variant="subtitle2"
        fontWeight="bold"
        sx={{
          marginTop: "20px",
          marginBottom: "8px",
          color: "secondary.light",
        }}
      >
        User Name
      </Typography>
      <TextField
        name="name"
        value={formData?.name}
        onChange={handleOnChange}
        label=""
        size="small"
        sx={{
          width: "100%",
          "& .MuiInputBase-root": {
            backgroundColor: "#F0F0F0",
          },
        }}
      />

      {!id && (
        <Box>
          <Typography
            variant="subtitle2"
            fontWeight="bold"
            sx={{
              marginTop: "20px",
              marginBottom: "8px",
              color: "secondary.light",
            }}
          >
            Email
          </Typography>
          <TextField
            name="email"
            value={formData?.email}
            onChange={handleOnChange}
            label=""
            size="small"
            sx={{
              width: "100%",
              "& .MuiInputBase-root": {
                backgroundColor: "#F0F0F0",
              },
            }}
          />
        </Box>
      )}

      {!id && (
        <Box>
          <Typography
            variant="subtitle2"
            fontWeight="bold"
            sx={{
              marginTop: "20px",
              marginBottom: "8px",
              color: "secondary.light",
            }}
          >
            Password
          </Typography>
          <TextField
            name="password"
            type="password"
            value={formData?.password}
            onChange={handleOnChange}
            label=""
            size="small"
            sx={{
              width: "100%",
              "& .MuiInputBase-root": {
                backgroundColor: "#F0F0F0",
              },
            }}
          />
        </Box>
      )}

      <Typography
        variant="subtitle2"
        fontWeight="bold"
        sx={{
          marginTop: "20px",
          marginBottom: "8px",
          color: "secondary.light",
        }}
      >
        Role
      </Typography>
      <FormControl
        sx={{
          width: "100%",
          "& .MuiInputBase-root": {
            backgroundColor: "#F0F0F0",
          },
          mb: 2,
        }}
      >
        <Select
          size="small"
          name="role"
          value={formData.role}
          onChange={handleOnChange}
          sx={{
            border: "none",
            borderRadius: 1,
            "& .MuiInputBase-root": {
              backgroundColor: "#F0F0F0",
              borderRadius: 1,
              outline: "none",
              "&:hover": { backgroundColor: "#F0F0F0" },
              color: "#555",
            },
            backgroundColor: "#F0F0F0",
          }}
        >
          <MenuItem value="I_Admin">Admin</MenuItem>
          <MenuItem value="I_Operation">Operation</MenuItem>
          <MenuItem value="I_Billing">Billing</MenuItem>
        </Select>
      </FormControl>

      {id && (
        <Box sx={{ width: "100%", mt: 4 }}>
          <Button
            color="warning"
            fullWidth
            variant="outlined"
            sx={{
              flex: 1,
              height: 35,
            }}
            onClick={handleDialogOnClick}
          >
            Reset Password
          </Button>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          mt: 2,
          width: "100%",
          justifyContent: { xs: "center", lg: "space-between" },
        }}
      >
        <Button
          variant="contained"
          type="submit"
          sx={{
            flex: 1,
            height: 35,
            boxShadow: "none",
          }}
          onClick={() => navigate(-1)}
        >
          {id ? "Edit" : "Create"}
        </Button>
        <Button
          color="error"
          variant="outlined"
          fullWidth
          sx={{
            flex: 1,
            height: 35,
          }}
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
      </Box>
      <ResetPasswordDialog
        open={open}
        handleDialogOnClick={handleDialogOnClick}
      />
    </Box>
  );
};

export default CreateNewUsers;
