import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PackagesList from "./PackagesList";
import PackageDetails from "./PackageDetails";
import PackageForm from "./PackageForm";

const Packages = () => {
  return (
    <Routes>
      <Route path='' element={<PackagesList />} />
      <Route path='details/:id' element={<PackageDetails />} />
      <Route path='add' element={<PackageForm />} />
      <Route path='edit/:id' element={<PackageForm />} />
      <Route path='*' element={<Navigate to='' replace />} />
    </Routes>
  );
};

export default Packages;
