import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AdvertisementsList from "./AdvertisementsList";
import AdvertisementDetails from "./AdvertisementDetails";
import AdvertisementForm from "./AdvertisementForm";

const Advertisements = () => {
  return (
    <Routes>
      <Route path="" element={<AdvertisementsList />} />
      <Route path="details/:id" element={<AdvertisementDetails />} />
      <Route path="add" element={<AdvertisementForm />} />
      {/* <Route path="edit/:id" element={<AdvertisementForm />} /> */}
      <Route path="*" element={<Navigate to="" replace />} />
    </Routes>
  );
};

export default Advertisements;
