import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { InternalUsersList } from "../../components/users";

const UsersList = () => {
  const navigate = useNavigate();
  const [selection, setSelection] = useState([]);

  return (
    <Box
      sx={{
        height: "90%",
        width: "100%",
        px: { xs: "1rem", lg: "2rem" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="subtitle1"
          fontWeight="bold"
          sx={{ my: "1rem", fontSize: "1.2rem" }}
        >
          Users
        </Typography>
        <Box sx={{ display: "flex", my: "1.5rem", ml: "auto" }}>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            sx={{
              display: selection.length === 1 ? "none" : "flex",
              fontWeight: 700,
            }}
            onClick={() => navigate("add")}
          >
            New
          </Button>
          <Button
            color="warning"
            variant="outlined"
            size="small"
            sx={{
              display: selection.length === 1 ? "flex" : "none",
              fontWeight: 700,
            }}
            onClick={() => navigate("edit/" + selection[0])}
          >
            Edit
          </Button>
        </Box>
      </Box>
      <InternalUsersList
        handleOnSelecting={setSelection}
        sx={{ height: "90%" }}
      />
    </Box>
  );
};

export default UsersList;
