import { useState } from "react";
import { Box } from "@mui/material";
import { Tab, Tabs } from "@mui/material";
import TabPanel from "../../components/common/TabPanel";
import {
  PendingOrder,
  SuccessOrder,
  FailedOrder,
} from "../../components/order";

const OrderList = () => {
  const [tab, setTab] = useState(0);
  const [selection, setSelection] = useState([]);

  const handleChange = (event, newTab) => {
    setTab(newTab);
  };

  return (
    <Box
      sx={{
        height: "90%",
        width: "100%",
        px: { xs: "1rem", lg: "2rem" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Tabs
          value={tab}
          onChange={handleChange}
          variant="scrollable"
          allowScrollButtonsMobile
          sx={{
            minHeight: "24px",
            "& .MuiTab-root": { minHeight: "24px", padding: "4px" },
            pt: 2,
          }}
        >
          <Tab label="Pending" />
          <Tab label="Success" />
          <Tab label="Failed" />
        </Tabs>
      </Box>
      <TabPanel value={tab} index={0}>
        <PendingOrder handleOnSelecting={setSelection} />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <SuccessOrder handleOnSelecting={setSelection} />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <FailedOrder handleOnSelecting={setSelection} />
      </TabPanel>
    </Box>
  );
};

export default OrderList;
