import { useState, useMemo, React, useCallback } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { useAxios } from "../../hooks";
import ComfirmDialog from "../common/ConfirmDialog";
import RejectDialog from "./RejectDialog";
import { getDateTime } from "../../utilities/getDateTime";
import { Box } from "@mui/material";

export default function AppliedList({ handleOnSelecting }) {
  const api = useAxios({ autoSnackbar: true });
  const queryClient = useQueryClient();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setrowsPerPage] = useState(25);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({ brandname_id: "", action: "" });

  const [rejectdata, setRejectData] = useState({
    brandname_id: "",
    reason: "",
    action: "",
  });

  const handleOpen = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const { data: res, isLoading } = useQuery(
    `brandname-list_applied_?page=${page}&rowsPerPage=${rowsPerPage}`,
    () => {
      return api.get(
        `api/brandname/i/list/APPLIED/p?page=${page}&rowsPerPage=${rowsPerPage}`
      );
    }
  );

  const { mutate: applyMutation } = useMutation(
    () => {
      return api.put(
        `/api/brandname/i/status/${data.brandname_id}?application_status=${data.action}`
      );
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(
          `brandname-list_applied_?page=${page}&rowsPerPage=${rowsPerPage}`
        );
      },
    }
  );

  const { mutate: rejectMutation } = useMutation(
    () => {
      return api.post(`/api/brandname/i/${rejectdata.brandname_id}/reject`, {
        reason: rejectdata.reason,
        action: rejectdata.action,
      });
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(
          `brandname-list_applied_?page=${page}&rowsPerPage=${rowsPerPage}`
        );
      },
    }
  );

  const handleReasonChange = (event) => {
    setRejectData((prevData) => ({
      ...prevData,
      reason: event.target.value,
    }));
  };

  const columns = useMemo(() => {
    return [
      { field: "id", headerName: "ID", minWidth: 90, flex: 0.5 },
      { field: "sender_id", headerName: "Sender ID", minWidth: 90, flex: 0.5 },
      {
        field: "company_name",
        headerName: "Company Name",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "email",
        headerName: "Company Email",
        width: 150,
        minWidth: 100,
        valueGetter: (params) => params.row.company?.email ?? "",
        flex: 1,
      },
      {
        field: "created_at",
        headerName: "Created At",
        minWidth: 100,
        flex: 1,
        valueFormatter: (params) => getDateTime(params.value),
      },
      {
        field: "updated_at",
        headerName: "Updated At",
        minWidth: 100,
        flex: 1,
        valueFormatter: (params) => getDateTime(params.value),
      },
      {
        field: "action",
        headerName: "Action",
        sortable: false,
        minWidth: 180,
        flex: 1,
        renderCell: (params) => (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              variant="outlined"
              color="success"
              size="small"
              onClick={() => {
                setData({
                  brandname_id: params.id,
                  action: "ACCEPTED",
                });
                handleOpen();
              }}
            >
              <Typography sx={{ fontSize: "11px" }}>Accept</Typography>
            </Button>
            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={() => {
                setRejectData({
                  brandname_id: params.id,
                  name: params.row.name,
                  action: "REJECTED",
                });
                handleOpen();
              }}
            >
              <Typography sx={{ fontSize: "11px" }}>Reject</Typography>
            </Button>
          </Box>
        ),
      },
    ];
  }, [setData, handleOpen]);

  return (
    <>
      <DataGrid
        columns={columns}
        rowCount={res?.data?.total ?? 0}
        rows={res?.data?.rows ?? []}
        loading={isLoading}
        pagination
        paginationMode="server"
        checkboxSelection
        onSelectionModelChange={handleOnSelecting}
        page={page}
        pageSize={rowsPerPage}
        onPageChange={(v) => setPage(v)}
        onPageSizeChange={(v) => setrowsPerPage(v)}
      />
      <ComfirmDialog
        open={open}
        handleClose={handleOpen}
        handleClickOpen={handleOpen}
        handleOnConfirm={applyMutation}
      />
      {rejectdata.action === "REJECTED" && (
        <RejectDialog
          open={open}
          data={rejectdata}
          handleClose={handleOpen}
          handleOnRejectConfirm={rejectMutation}
          handleReasonChange={handleReasonChange}
        />
      )}
    </>
  );
}
