import React from 'react';
import { Box, Typography } from '@mui/material';

const Analytics = () => {
  return (
    <Box
      component='main'
      sx={{
        display: "grid",
        placeItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <Typography variant='h1'>This is Analytics</Typography>
    </Box>
  );
};

export default Analytics;
