import { Box, Button, Divider, Grid, Toolbar, Typography } from "@mui/material";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useAxios } from "../../hooks";
import BackButton from "../../components/common/BackButton";
import { getDateTime } from "../../utilities/getDateTime";
import ComfirmDialog from "../../components/common/ConfirmDialog";
import { useSetRecoilState } from "recoil";
import { withAlert } from "../../recoil/snackbar";

const AdvertisementDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const api = useAxios({ autoSnackbar: true });
  const queryClient = useQueryClient();
  const openAlert = useSetRecoilState(withAlert);
  const baseURL = process.env.REACT_APP_FILE_BASE_URL;

  const [open, setOpen] = useState(false);
  const [data, setData] = useState({ package_id: "", action: "" });

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const { data: res } = useQuery(`advertisement-details_${id}`, () => {
    return api.get(`/api/advertisement/i/${id}`);
  });

  const { mutate } = useMutation(
    () => {
      return api.put(
        `/api/advertisement/i/status/${data.advertisement_id}?status=${data.action}`
      );
    },
    {
      onSuccess: (res) => {
        openAlert({
          status: res.status,
          detail:
            typeof res.data.message === "string"
              ? res.data.message
              : res.data.message[0],
        });
        queryClient.invalidateQueries(`advertisement-details_${id}`);
      },
    }
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        p: 2,
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
        variant="dense"
        disableGutters={true}
      >
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          <BackButton backFunction={() => navigate(-1)} />
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Details
          </Typography>
        </Box>

        <Box
          sx={{
            display: res?.data?.is_public === undefined ? "none" : "flex",
            gap: 1,
          }}
        >
          <Button
            variant="outlined"
            color="success"
            size="small"
            sx={{
              display: res?.data?.is_public === true ? "none" : "inline-block",
            }}
            onClick={() => {
              setData({
                advertisement_id: res?.data.id,
                action: !res?.data?.is_public,
              });
              handleOpen();
            }}
          >
            <Typography sx={{ fontSize: "11px" }}>Activate</Typography>
          </Button>
          <Button
            variant="outlined"
            color="error"
            size="small"
            sx={{
              display: res?.data?.is_public === false ? "none" : "inline-block",
            }}
            onClick={() => {
              setData({
                advertisement_id: res?.data.id,
                action: !res?.data?.is_public,
              });
              handleOpen();
            }}
          >
            <Typography sx={{ fontSize: "11px" }}>Deactivate</Typography>
          </Button>
        </Box>
      </Toolbar>

      <Divider sx={{ mt: "12px", mb: "16px" }} />
      <Grid container sx={{ width: "100%" }} spacing={1}>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Advertisement ID
          </Typography>
          <Typography variant="h6">{res?.data?.id}</Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Subject
          </Typography>
          <Typography variant="h6">{res?.data?.subject}</Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Active status
          </Typography>
          <Typography
            variant="h6"
            sx={{ color: res?.data?.is_public ? "green" : "red" }}
          >
            {res?.data?.is_public ? "Public" : "Inactive"}
          </Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Action
          </Typography>
          <Typography variant="h6">{res?.data?.action}</Typography>
        </Grid>
      </Grid>
      <Divider sx={{ mt: "12px", mb: "16px" }} />
      <Grid container sx={{ width: "100%" }} spacing={1}>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            image
          </Typography>
          <img
            src={`${baseURL}/advertisement/${res?.data?.image_filename}`}
            alt="Advertisement Pic"
            style={{ width: "100%", height: "auto" }}
          />
          {/* <img
            src={"/assets/BoomSMSLogo.png"}
            style={{ width: "100%", height: "auto" }}
          /> */}
        </Grid>
      </Grid>
      <Divider sx={{ mt: "12px", mb: "16px" }} />
      <Grid container sx={{ width: "100%" }} spacing={1}>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Created At
          </Typography>
          <Typography variant="h6">
            {getDateTime(res?.data?.created_at)}
          </Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Updated At
          </Typography>
          <Typography variant="h6">
            {getDateTime(res?.data?.updated_at)}
          </Typography>
        </Grid>
      </Grid>
      <ComfirmDialog
        open={open}
        handleClose={handleOpen}
        handleClickOpen={handleOpen}
        handleOnConfirm={mutate}
      />
    </Box>
  );
};

export default AdvertisementDetails;
