import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import CreateNewAnnoucement from "./CreateNewAnnoucement";
import AnnoucementList from "./AnnoucementList";

const Annoucement = () => {
  return (
    <Routes>
      <Route path="" element={<AnnoucementList />} exact />
      <Route path="*" element={<Navigate to={""} replace />} />
      <Route path="add" element={<CreateNewAnnoucement />} />
      <Route path="edit/:id" element={<CreateNewAnnoucement />} />
    </Routes>
  );
};

export default Annoucement;
