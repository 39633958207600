import { useState, useCallback, useMemo } from "react";
import useAxios from "../../hooks/useAxios";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Tooltip, IconButton, Box, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import PhoneGroupEdit from "./PhoneGroupEdit";
import { useMutation, useQueryClient } from "react-query";
import ConfirmDialog from "../common/ConfirmDialog";
import NoRowOverlay from "../common/NoRowOverlay";

export default function PhonegroupTable({ handleOnSelecting }) {
  const { id } = useParams();
  const api = useAxios({ autoSnackbar: true });
  const queryClient = useQueryClient();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [data, setData] = useState([]);
  const [phoneToDeleteId, setPhoneToDeleteId] = useState(null);
  const [phoneEditInfo, setPhoneEditInfo] = useState({
    phoneToEditId: null,
    phoneToEdit: null,
  });

  const handlePhoneGroupEditOpen = (idToEdit, phoneNumber) => {
    // setPhoneToEdit(phoneNumber);
    setPhoneEditInfo({ phoneToEditId: idToEdit, phoneToEdit: phoneNumber });
  };

  const { data: res, isLoading } = useQuery(
    `phonegroup-numbers_page=${page}_rowsPerPage=${pageSize}`,
    () => {
      return api.get(`/api/phonegroup/i/numbers/${id}`);
    }
  );

  const { mutate: deleteNumber } = useMutation(
    (idToDelete) => {
      return api.delete(`/api/phonegroup/i/${id}?phone_id=${idToDelete}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          `phonegroup-numbers_page=${page}_rowsPerPage=${pageSize}`
        );
      },
    }
  );

  const handleDeleteConfirmation = () => {
    handleDeleteNumber(phoneToDeleteId);
  };

  const handleDeleteNumber = (idToDelete) => {
    deleteNumber(idToDelete);
  };

  const columns = useMemo(
    () => [
      // { field: "id", headerName: "ID", minWidth: 90, flex: 0.5 },
      { field: "phone_number", headerName: "Phone", minWidth: 100, flex: 1 },
      {
        field: "action",
        headerName: "Action",
        sortable: false,
        minWidth: 100,
        flex: 0.5,
        renderCell: (params) => (
          <Box
            sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
          >
            <Tooltip title="Edit">
              <IconButton
                size="small"
                onClick={() =>
                  handlePhoneGroupEditOpen(
                    params.row.id,
                    params.row.phone_number
                  )
                }
              >
                <EditIcon
                  sx={{
                    cursor: "pointer",
                    "&:hover": { color: "primary.main" },
                  }}
                />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete">
              <IconButton
                size="small"
                onClick={() => {
                  setPhoneToDeleteId(params.row.id);
                }}
              >
                <RemoveCircleOutlineIcon
                  sx={{
                    cursor: "pointer",
                    "&:hover": { color: "red" },
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <Typography
        variant="subtitle1"
        color="secondary.light"
        fontWeight="bold"
        sx={{ pb: 2 }}
      >
        Total Phone Number - {res?.data?.total}
      </Typography>
      <DataGrid
        columns={columns}
        rowCount={res?.data?.total ?? 0}
        rows={res?.data?.rows ?? []}
        loading={isLoading}
        pagination
        paginationMode="server"
        page={page}
        pageSize={pageSize}
        onPageChange={(v) => setPage(v)}
        onPageSizeChange={(v) => setPageSize(v)}
        pageSizeOptions={[25, 50, 100]}
        components={{
          Toolbar: GridToolbar,
          NoRowsOverlay: NoRowOverlay,
        }}
      />
      <PhoneGroupEdit
        open={Boolean(phoneEditInfo.phoneToEditId)}
        handleClose={() =>
          setPhoneEditInfo({
            ...phoneEditInfo,
            phoneToEditId: null,
          })
        }
        phoneToEdit={phoneEditInfo.phoneToEdit}
        phoneToEditId={phoneEditInfo.phoneToEditId}
        id={id}
      />
      <ConfirmDialog
        open={Boolean(phoneToDeleteId)}
        handleClose={() => setPhoneToDeleteId(null)}
        handleOnConfirm={handleDeleteConfirmation}
        dialog_content="Do you really want to delete this phone number? This action cannot be undone."
      />
    </>
  );
}
