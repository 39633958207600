import { Box } from "@mui/material";
import { Navigate, Route, Routes } from "react-router-dom";
import Me from "./Me";

const Profile = () => {
  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <Routes>
        <Route path="me" element={<Me />} />
        <Route path="*" element={<Navigate to="me" replace />} />
      </Routes>
    </Box>
  );
};

export default Profile;
