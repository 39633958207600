import { DialogContentText } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

export default function ConfirmDialog({
  open,
  dialog_title = "Are you sure?",
  dialog_content = "Do you really want to change the status. This action will be impact on usability.",
  handleClose,
  handleOnConfirm,
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "10px",
          width: "90%",
        },
      }}
    >
      <DialogTitle>{dialog_title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialog_content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='error'>
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleOnConfirm();
            handleClose();
          }}
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
