import React, { useCallback, useMemo, useState } from "react";
import { useAxios } from "../../hooks";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ConfirmOrderForm from "./ConfirmOrderForm";
import NoRowOverlay from "../common/NoRowOverlay";
import { Box, Tooltip, IconButton } from "@mui/material";
import PaidIcon from "@mui/icons-material/Paid";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import { getDateTime } from "../../utilities/getDateTime";
import ComfirmDialog from "../common/ConfirmDialog";
import FeaturesSegegration from "../../hocs/FeaturesSegegration";
import UserRoles from "../../utilities/userRoles";

const PendingOrder = ({ handleOnSelecting }) => {
  const api = useAxios({ autoSnackbar: true });
  const queryClient = useQueryClient();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [open, setOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({
    id: null,
    company_name: "",
    order_number: "",
    package_price: "",
  });

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [data, setData] = useState({ order_id: "" });

  const handleOpen = useCallback(
    (orderId, companyName, orderNumber, packagePrice) => {
      setOpen(true);
      setSelectedOrder((prevSelectedOrder) => ({
        ...prevSelectedOrder,
        id: orderId,
        company_name: companyName,
        order_number: orderNumber,
        package_price: packagePrice,
      }));
    },
    []
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleConfirmOpen = useCallback(() => {
    setConfirmOpen((prev) => !prev);
  }, []);

  const { data: res, isLoading } = useQuery(
    `order-list_pending_page=${page}_rowsPerPage=${pageSize}`,
    () => {
      return api.get(
        `/api/order/i/PENDING/p?page=${page}&rowsPerPage=${pageSize}`
      );
    }
  );

  const { mutate } = useMutation(
    () => {
      return api.patch(`/api/order/i/payment/failed/${data.order_id}`);
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(
          `order-list_pending_page=${page}_rowsPerPage=${pageSize}`
        );
      },
    }
  );

  const columns = useMemo(
    () => [
      { field: "id", headerName: "ID", minWidth: 90, flex: 0.5 },
      {
        field: "order_number",
        headerName: "Order Number",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "company_name",
        headerName: "Company Name",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "package_name",
        headerName: "Package Name",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "package_credit",
        headerName: "Package Credit",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "package_price",
        headerName: "Package Price",
        minWidth: 100,
        flex: 1,
      },
      { field: "package_tax", headerName: "Tax", minWidth: 100, flex: 1 },
      {
        field: "created_at",
        headerName: "Created At",
        minWidth: 100,
        flex: 1,
        valueFormatter: (params) => getDateTime(params.value),
      },
      {
        field: "action",
        headerName: "Action",
        sortable: false,
        minWidth: 100,
        flex: 1,
        renderCell: (params) => (
          <FeaturesSegegration roles={[UserRoles.BILLING]} fallbackRoute={null}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Tooltip title="Make Payment">
                <IconButton
                  color="success"
                  size="small"
                  onClick={() => {
                    handleOpen(
                      params.row.id,
                      params.row.company_name,
                      params.row.order_number || "",
                      params.row.package_price
                    );
                  }}
                >
                  <PaidIcon
                    sx={{
                      cursor: "pointer",
                    }}
                  />
                </IconButton>
              </Tooltip>

              <Tooltip title="Failed">
                <IconButton
                  size="small"
                  color="error"
                  onClick={() => {
                    setData({
                      order_id: params.id,
                    });
                    handleConfirmOpen();
                  }}
                >
                  <DoDisturbOnIcon
                    sx={{
                      cursor: "pointer",
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          </FeaturesSegegration>
        ),
      },
    ],
    []
  );

  return (
    <>
      <DataGrid
        columns={columns}
        rowCount={res?.data?.total ?? 0}
        rows={res?.data?.rows ?? []}
        loading={isLoading}
        pagination
        paginationMode="server"
        checkboxSelection
        onSelectionModelChange={handleOnSelecting}
        page={page}
        pageSize={pageSize}
        onPageChange={(v) => setPage(v)}
        onPageSizeChange={(v) => setPageSize(v)}
        components={{
          Toolbar: GridToolbar,
          NoRowsOverlay: NoRowOverlay,
        }}
      />
      <ConfirmOrderForm
        open={open}
        handleClose={handleClose}
        handleClickOpen={handleOpen}
        orderId={selectedOrder.id}
        companyName={selectedOrder.company_name}
        orderNumber={selectedOrder.order_number}
        packagePrice={selectedOrder.package_price}
      />
      <ComfirmDialog
        open={confirmOpen}
        handleClose={handleConfirmOpen}
        handleOnConfirm={mutate}
      />
    </>
  );
};

export default PendingOrder;
