import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useMutation, useQueryClient } from "react-query";
import useAxios from "../../hooks/useAxios";
import { useState, useEffect } from "react";

export default function PhoneGroupEdit({
  open,
  handleClose,
  phoneToEditId,
  phoneToEdit,
  id,
}) {
  const api = useAxios({ autoSnackbar: true });
  const queryClient = useQueryClient();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const [formData, setFormData] = useState({
    phone_number: "",
  });

  useEffect(() => {
    if (phoneToEdit) {
      setFormData((prevState) => ({
        ...prevState,
        phone_number: phoneToEdit,
      }));
    }
  }, [phoneToEdit]);

  const { mutate } = useMutation(
    () => {
      return api.patch(
        `/api/phonegroup/i/${id}?phone_id=${phoneToEditId}`,
        formData
      );
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(
          `phonegroup-numbers_page=${page}_rowsPerPage=${pageSize}`
        );
        handleClose();
      },
    }
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      component="form"
      PaperProps={{
        style: { borderRadius: "10px" },
      }}
    >
      <DialogTitle>Update Phone Number</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ pb: 2 }}>
          Please enter the new phone number to update the data in the phone
          group.
        </DialogContentText>
        <TextField
          autoFocus
          name="phone_number"
          margin="dense"
          value={formData.phone_number}
          onChange={(e) =>
            setFormData({ ...formData, phone_number: e.target.value })
          }
          label="Phone Number"
          type="tel"
          fullWidth
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="error">
          Cancel
        </Button>
        <Button onClick={() => mutate()} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
