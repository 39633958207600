import React, { useState } from "react";
import { Box, Button, Divider, Grid, Toolbar, Typography } from "@mui/material";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../components/common/BackButton";
import { useAxios } from "../../hooks";
import { getDateTime } from "../../utilities/getDateTime";
import CompanyUserList from "../../components/company/CompanyUserList";
import { useSetRecoilState } from "recoil";
import { withAlert } from "../../recoil/snackbar";
import ComfirmDialog from "../../components/common/ConfirmDialog";
import CompanyPackageList from "../../components/company/CompanyPackageList";
import AddPackagesDialog from "../../components/company/AddPackagesDialog";
import AddPhonegroupDialog from "../../components/company/AddPhonegroupDialog";
import CompanyPhonegroupList from "../../components/company/CompanyPhonegroupList";
import CompanyBrandNameList from "../../components/company/CompanyBrandNameList";
import AddBrandNamesDialog from "../../components/company/AddBrandNamesDialog";

const CompanyDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const api = useAxios({ autoSnackbar: true });
  const openAlert = useSetRecoilState(withAlert);
  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const [packagesDialogOpen, setPackagesDialogOpen] = useState(false);
  const [phonegroupDialogOpen, setPhonegroupDialogOpen] = useState(false);
  const [brandNameDialogOpen, setBrandNameDialogOpen] = useState(false);
  const [data, setData] = useState({ company_id: "", action: "" });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handlePackagesDialogOpen = () => {
    setPackagesDialogOpen(true);

    queryClient.invalidateQueries(`package-list_active_${id}`);
  };

  const handlePhonegroupDialogOpen = () => {
    setPhonegroupDialogOpen(true);

    queryClient.invalidateQueries(`phonegroup-list_active_${id}`);
  };

  const handleBrandNameDialogOpen = () => {
    setBrandNameDialogOpen(true);
    queryClient.invalidateQueries(`brand_name_list_active_${id}`);
  };

  const handlePackagesDialogClose = () => setPackagesDialogOpen(false);
  const handlePhonegroupDialogClose = () => setPhonegroupDialogOpen(false);
  const handleBrandNameDialogClose = () => setBrandNameDialogOpen(false);

  const { data: res } = useQuery(`company-details_${id}`, () => {
    return api.get(`/api/company/i/details/${id}`);
  });

  const { mutate: changeStatus } = useMutation(
    () => {
      return api.put(`/api/company/i/status/${id}?status=${data.action}`);
    },
    {
      onSuccess: (res) => {
        openAlert({
          status: res.status,
          detail:
            typeof res.data.message === "string"
              ? res.data.message
              : res.data.message[0],
        });
        queryClient.invalidateQueries(`company-details_${id}`);
      },
    }
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        p: 2,
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
        variant="dense"
        disableGutters={true}
      >
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          <BackButton backFunction={() => navigate(-1)} />
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Details
          </Typography>
        </Box>

        <Box
          sx={{
            display: res?.status === undefined ? "none" : "flex",
            gap: 1,
          }}
        >
          <Button
            variant="outlined"
            color="warning"
            size="small"
            sx={{
              display:
                res?.data?.status === "REQUESTED" ? "none" : "inline-block",
            }}
            onClick={() => {
              setData({
                company_id: res?.data.id,
                name: res?.data.name,
                action: "REQUESTED",
              });
              handleOpen();
            }}
          >
            <Typography
              sx={{
                fontSize: "11px",
              }}
            >
              Request
            </Typography>
          </Button>
          <Button
            variant="outlined"
            color="success"
            size="small"
            sx={{
              display: res?.data?.status === "ACTIVE" ? "none" : "inline-block",
            }}
            onClick={() => {
              setData({
                company_id: res?.data.id,
                name: res?.data.name,
                action: "ACTIVE",
                content: (
                  <>
                    <b>{res?.data.name}&nbsp;</b>is going to be Activate.
                    <b style={{ color: "green" }}>
                      They will be accessible&nbsp;
                    </b>
                    to all Tabs and features of BOOM-SMS portal after this
                    action.
                  </>
                ),
              });
              handleOpen();
            }}
          >
            <Typography sx={{ fontSize: "11px" }}>Activate</Typography>
          </Button>
          <Button
            variant="outlined"
            color="error"
            size="small"
            sx={{
              display:
                res?.data?.status === "INACTIVE" ? "none" : "inline-block",
            }}
            onClick={() => {
              setData({
                company_id: res?.data.id,
                action: "INACTIVE",
              });
              handleOpen();
            }}
          >
            <Typography sx={{ fontSize: "11px" }}>Deactivate</Typography>
          </Button>
        </Box>
      </Toolbar>

      <Divider sx={{ mt: "12px", mb: "16px" }} />
      <Grid container sx={{ width: "100%" }} spacing={1}>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Company ID
          </Typography>
          <Typography variant="h6">{res?.data?.id}</Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Company Name
          </Typography>
          <Typography variant="h6">{res?.data?.name}</Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Status
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color:
                res?.data?.status === "ACTIVE"
                  ? "green"
                  : res?.data?.status === "REQUESTED"
                  ? "orange"
                  : "red",
            }}
          >
            {res?.data?.status}
          </Typography>
        </Grid>
        <Grid container sx={{ width: "100%", mt: 1 }} spacing={1}>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <Typography variant="subtitle2" sx={{ color: "grey" }}>
              Phone Number
            </Typography>
            <Typography variant="h6">{res?.data?.phone_number}</Typography>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <Typography variant="subtitle2" sx={{ color: "grey" }}>
              Email
            </Typography>
            <Typography variant="h6">{res?.data?.email}</Typography>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <Typography variant="subtitle2" sx={{ color: "grey" }}>
              Company Type
            </Typography>
            <Typography variant="h6">{res?.data?.company_type}</Typography>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <Typography variant="subtitle2" sx={{ color: "grey" }}>
              Address
            </Typography>
            <Typography variant="h6">{res?.data?.address}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Divider sx={{ mt: "12px", mb: "16px" }} />
      <Grid container sx={{ width: "100%" }} spacing={1}>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Credit
          </Typography>
          <Typography variant="h6">{res?.data?.credit}</Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Reserved Credit
          </Typography>
          <Typography variant="h6">{res?.data?.reserved_credit}</Typography>
        </Grid>
      </Grid>
      <Divider sx={{ mt: "12px", mb: "12px" }} />
      <Grid container sx={{ width: "100%" }} spacing={1}>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Created At
          </Typography>
          <Typography variant="h6">
            {getDateTime(res?.data?.created_at)}
          </Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Updated At
          </Typography>
          <Typography variant="h6">
            {getDateTime(res?.data?.updated_at)}
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ mt: "12px", mb: "2rem" }} />
      <Typography variant="subtitle" sx={{ mb: "1rem", fontWeight: 700 }}>
        Company Users
      </Typography>
      <Box sx={{ height: 400, width: "100%", mb: "2rem" }}>
        <CompanyUserList id={id} />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="subtitle" sx={{ mb: "1rem", fontWeight: 700 }}>
          Company Brandnames
        </Typography>
        <Box>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            sx={{
              fontWeight: 700,
            }}
            onClick={handleBrandNameDialogOpen}
          >
            Add
          </Button>
        </Box>
      </Box>
      <Box sx={{ height: 400, width: "100%", mb: "2rem" }}>
        <CompanyBrandNameList />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="subtitle" sx={{ mb: "1rem", fontWeight: 700 }}>
          Company Packages
        </Typography>
        <Box>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            sx={{
              fontWeight: 700,
            }}
            onClick={handlePackagesDialogOpen}
          >
            Add
          </Button>
        </Box>
      </Box>
      <Box sx={{ height: 400, width: "100%", mb: "2rem" }}>
        <CompanyPackageList packages={res?.data?.packages || []} />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="subtitle" sx={{ mb: "1rem", fontWeight: 700 }}>
          Company Phone Group
        </Typography>
        <Box>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            sx={{
              fontWeight: 700,
            }}
            onClick={handlePhonegroupDialogOpen}
          >
            Add
          </Button>
        </Box>
      </Box>
      <Box sx={{ height: 400, width: "100%", mb: "2rem" }}>
        <CompanyPhonegroupList />
      </Box>

      <ComfirmDialog
        open={open}
        handleClose={handleClose}
        handleClickOpen={handleOpen}
        handleOnConfirm={changeStatus}
      />

      <AddPackagesDialog
        open={packagesDialogOpen}
        handleClose={handlePackagesDialogClose}
      />

      <AddPhonegroupDialog
        open={phonegroupDialogOpen}
        handleClose={handlePhonegroupDialogClose}
      />
      <AddBrandNamesDialog
        open={brandNameDialogOpen}
        handleClose={handleBrandNameDialogClose}
      />
    </Box>
  );
};

export default CompanyDetails;
