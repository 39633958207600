import { Box, Divider, Grid, Toolbar, Typography, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useAxios } from "../../hooks";
import BackButton from "../../components/common/BackButton";
import { getDateTime } from "../../utilities/getDateTime";
import { useQuery, useQueryClient, useMutation } from "react-query";
import ComfirmDialog from "../../components/common/ConfirmDialog";
import { useState, React, useCallback } from "react";
import RejectDialog from "../../components/brandname/RejectDialog";

const BrandnameDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const api = useAxios({ autoSnackbar: true });
  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const [data, setData] = useState({ brandname_id: "", name: "", action: "" });

  const [rejectdata, setRejectData] = useState({
    brandname_id: "",
    reason: "",
    status: "",
  });

  const handleOpen = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const { data: res } = useQuery(`brandname-details_${id}`, () => {
    return api.get(`/api/brandname/i/detail/${id}`);
  });

  const authorizedLetterUrl = `${process.env.REACT_APP_FILE_BASE_URL}/brandname/${res?.data?.authorized_letter_filename}`;
  const companyCertificateUrl = `${process.env.REACT_APP_FILE_BASE_URL}/brandname/${res?.data?.company_registration_certificate_filename}`;

  const handleDownloadClick = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const { mutate: applyMutation } = useMutation(
    () => {
      return api.put(
        `/api/brandname/i/status/${res?.data?.id}?application_status=${data.action}`
      );
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(`brandname-details_${id}`);
      },
    }
  );

  const { mutate: rejectMutation } = useMutation(
    () => {
      return api.post(`/api/brandname/i/${res?.data?.id}/reject`, {
        reason: rejectdata.reason,
        action: rejectdata.action,
      });
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(`brandname-details_${id}`);
      },
    }
  );
  const handleReasonChange = (event) => {
    setRejectData((prevData) => ({
      ...prevData,
      reason: event.target.value,
    }));
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "ACTIVE":
        return "green";
      case "APPLIED":
        return "blue";
      case "INACTIVE":
        return "grey";
      case "ACCEPTED":
        return "green";
      case "REJECTED":
        return "red";
      default:
        return "black";
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%", m: 2 }}>
      <Toolbar
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: { xs: "wrap", md: "nowrap" },
          flexGrow: 1,
          gap: { xs: 1, md: 0 },
        }}
        variant="dense"
        disableGutters={true}
      >
        <BackButton backFunction={() => navigate(-1)} />
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Brand Name Details
        </Typography>

        <Box
          sx={{
            display:
              res?.data?.application_status === undefined ? "none" : "flex",
            gap: 1,
          }}
        >
          <Button
            variant="outlined"
            size="small"
            color="info"
            sx={{
              display:
                res?.data?.application_status === "APPLIED"
                  ? "none"
                  : "inline-block",
            }}
            onClick={() => {
              setData({
                brandname_id: res?.data?.id,
                name: res?.data?.sender_id,
                action: "APPLIED",
              });
              handleOpen();
            }}
          >
            APPLY
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="success"
            sx={{
              display:
                res?.data?.application_status === "ACCEPTED"
                  ? "none"
                  : "inline-block",
            }}
            onClick={() => {
              setData({
                brandname_id: res?.data?.id,
                name: res?.data?.sender_id,
                action: "ACCEPTED",
              });
              handleOpen();
            }}
          >
            ACCEPT
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="success"
            sx={{
              display:
                res?.data?.application_status === "ACTIVE"
                  ? "none"
                  : "inline-block",
            }}
            onClick={() => {
              setData({
                brandname_id: res?.data?.id,
                name: res?.data?.sender_id,
                action: "ACTIVE",
              });
              handleOpen();
            }}
          >
            ACTIVE
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="warning"
            sx={{
              display:
                res?.data?.application_status === "INACTIVE"
                  ? "none"
                  : "inline-block",
            }}
            onClick={() => {
              setData({
                brandname_id: res?.data?.id,
                name: res?.data?.sender_id,
                action: "INACTIVE",
              });
              handleOpen();
            }}
          >
            INACTIVE
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="error"
            sx={{
              display:
                res?.data?.application_status === "REJECTED"
                  ? "none"
                  : "inline-block",
            }}
            onClick={() => {
              setRejectData({
                brandname_id: res?.data?.id,
                name: res?.data?.sender_id,
                action: "REJECTED",
              });
              handleOpen();
            }}
          >
            REJECT
          </Button>
        </Box>
      </Toolbar>
      <Divider sx={{ mt: "12px", mb: "16px" }} />
      <Grid container sx={{ width: "100%" }} spacing={1}>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Brandname ID
          </Typography>
          <Typography variant="h6">{res?.data?.id}</Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Company ID
          </Typography>
          <Typography variant="h6">{res?.data?.company_id}</Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Sender ID
          </Typography>
          <Typography variant="h6">{res?.data?.sender_id}</Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Content Sender ID
          </Typography>
          <Typography variant="h6">{res?.data?.content_sender_id}</Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Purpose
          </Typography>
          <Typography variant="h6">{res?.data?.purpose}</Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            ATOM
          </Typography>
          <Typography variant="h6">{res?.data?.atom}</Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Ooredoo
          </Typography>
          <Typography variant="h6">{res?.data?.ooredoo}</Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            MPT
          </Typography>
          <Typography variant="h6">{res?.data?.mpt}</Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Mytel
          </Typography>
          <Typography variant="h6">{res?.data?.mytel}</Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Application Status
          </Typography>
          <Typography
            variant="h6"
            sx={{ color: getStatusColor(res?.data?.application_status) }}
          >
            {res?.data?.application_status}
          </Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Created At
          </Typography>
          <Typography variant="h6">
            {getDateTime(res?.data?.created_at)}
          </Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Updated At
          </Typography>
          <Typography variant="h6">
            {getDateTime(res?.data?.updated_at)}
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ mt: "12px", mb: "12px" }} />
      <Grid container sx={{ width: "100%" }} spacing={1}>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Company Name
          </Typography>
          <Typography variant="h6">{res?.data?.company_name}</Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Company Registration Number
          </Typography>
          <Typography variant="h6">
            {res?.data?.company_registration_number}
          </Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Company Website URL
          </Typography>
          <Typography variant="h6">{res?.data?.company_website_url}</Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Company Description
          </Typography>
          <Typography variant="h6">{res?.data?.company_description}</Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            HeadQuarter Country
          </Typography>
          <Typography variant="h6">{res?.data?.headquarter_country}</Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Location Address In Myanmar
          </Typography>
          <Typography variant="h6">
            {res?.data?.location_address_in_myanmar}
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ mt: "12px", mb: "12px" }} />

      <Grid container sx={{ width: "100%", pt: 2 }} spacing={1}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey", pb: 2 }}>
            Company Registration Certificate
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleDownloadClick(companyCertificateUrl)}
          >
            Download Company Certificate
          </Button>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Typography
            variant="subtitle2"
            sx={{ color: "grey", pb: 2, pt: { xs: 6, md: 0 } }}
          >
            Authorized Letter
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleDownloadClick(authorizedLetterUrl)}
          >
            Download Authorized Letter
          </Button>
        </Grid>
      </Grid>
      {data.action !== "REJECTED" && (
        <ComfirmDialog
          open={open}
          handleClose={handleOpen}
          handleClickOpen={handleOpen}
          handleOnConfirm={applyMutation}
        />
      )}
      {rejectdata.action === "REJECTED" && (
        <RejectDialog
          open={open}
          data={rejectdata}
          handleClose={handleOpen}
          handleOnRejectConfirm={rejectMutation}
          handleReasonChange={handleReasonChange}
        />
      )}
    </Box>
  );
};

export default BrandnameDetails;
