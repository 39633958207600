import {
  Box,
  Button,
  Typography,
  TextField,
  Grid,
  Toolbar,
} from "@mui/material";
import { PhonegroupTable } from "../../components/phonegroup";
import BackButton from "../../components/common/BackButton";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useAxios } from "../../hooks";
import { getDateTime } from "../../utilities/getDateTime";

const PhonegroupDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const api = useAxios({ autoSnackbar: true });
  const queryClient = useQueryClient();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const [formData, setFormData] = useState({
    phone_numbers: [],
  });

  const [textFieldValue, setTextFieldValue] = useState("");

  const { data: res } = useQuery(`phonegroup-details_${id}`, () => {
    return api.get(`/api/phonegroup/i/details/${id}`);
  });

  const { mutate } = useMutation(
    () => {
      return api.post(`/api/phonegroup/i/add/phonenumbers/${id}`, formData);
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(
          `phonegroup-numbers_page=${page}_rowsPerPage=${pageSize}`
        );
        setFormData({ phone_numbers: [] });
      },
    }
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        px: { xs: 2, lg: 5 },
        py: 2,
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "center",
          gap: 1,
          pb: 2,
        }}
        variant="dense"
        disableGutters
      >
        <BackButton backFunction={() => navigate(-1)} />
        <Typography
          variant="h6"
          sx={{
            flex: "1",
            fontWeight: 500,
            fontSize: "1.2rem",
          }}
        >
          Phone Group Details
        </Typography>
      </Toolbar>
      <Grid container sx={{ width: "100%", pt: 1 }} spacing={1}>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Phone Group Name
          </Typography>
          <Typography
            variant="subtitle1"
            fontWeight="bold"
            color="secondary.light"
          >
            {res?.data?.name}
          </Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Description
          </Typography>
          <Typography
            variant="subtitle1"
            color="secondary.light"
            fontWeight="bold"
          >
            {res?.data?.description}
          </Typography>
        </Grid>

        <Grid item lg={4} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Created At
          </Typography>
          <Typography
            variant="subtitle1"
            color="secondary.light"
            fontWeight="bold"
          >
            {getDateTime(res?.data?.created_at)}
          </Typography>
        </Grid>
      </Grid>

      <Box
        sx={{
          mt: 3,
          display: "flex",
          width: "100%",
          gap: 2,
          flexDirection: {
            lg: "row",
            md: "column",
            sm: "column",
            xs: "column",
          },
        }}
      >
        <Box
          sx={{
            height: { lg: "600px", md: "500px", xs: "400px", sm: "400px" },
            width: { lg: "50%", md: "100%", xs: "100%", sm: "100%" },
          }}
        >
          <PhonegroupTable />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "flex-start",
            width: { lg: "50%", md: "100%" },
            pt: { lg: 0, md: 5, sm: 5, xs: 5 },
          }}
        >
          <Typography variant="subtitle1" color="secondary.light">
            Add new phone numbers to the current group
          </Typography>

          <TextField
            multiline
            rows={4}
            size="small"
            value={textFieldValue}
            sx={{
              width: "100%",
              borderRadius: 1,
              "& .MuiInputBase-root": {
                backgroundColor: "#F0F0F0",
                borderRadius: 1,
                "&:hover": {
                  backgroundColor: "#F0F0F0",
                },
              },
            }}
            InputProps={{
              placeholder:
                "Please enter mobile phone number (Have to start with 959). COMMA required between numbers.",
            }}
            onChange={(e) => {
              const phoneNumbers = e.target.value
                .split(",")
                .map((number) => number.trim());
              setFormData({ ...formData, phone_numbers: phoneNumbers });
              setTextFieldValue(e.target.value);
            }}
          />

          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              mutate();
              setTextFieldValue("");
            }}
            disableElevation
          >
            Add Phone Number
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PhonegroupDetails;
