import React, { useCallback, useMemo, useState } from "react";
import { useAxios } from "../../hooks";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Button, Typography, Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ComfirmDialog from "../common/ConfirmDialog";
import { getDateTime } from "../../utilities/getDateTime";

const InactiveAdvertisements = ({ handleOnSelecting }) => {
  const api = useAxios({ autoSnackbar: true });
  const queryClient = useQueryClient();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({ company_id: "", action: "" });

  const handleOpen = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const { data: res, isLoading } = useQuery(
    `advetisement-list_inactive`,
    () => {
      return api.get(`/api/advertisement/i/list/false`);
    }
  );

  const { mutate: deleteadvertisement } = useMutation(
    () => {
      return api.delete(`/api/advertisement/i/${data.advertisement_id}`);
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(`advetisement-list_inactive`);
      },
    }
  );

  const { mutate: statuschange } = useMutation(
    () => {
      return api.put(
        `/api/advertisement/i/status/${data.advertisement_id}?status=${data.action}`
      );
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(`advetisement-list_inactive`);
      },
    }
  );

  const columns = useMemo(
    () => [
      { field: "id", headerName: "ID", minWidth: 90, flex: 0.5 },
      // {
      //   field: "ad_filename",
      //   headerName: "Advertisement Filename",
      //   minWidth: 100,
      //   flex: 1,
      // },
      { field: "subject", headerName: "Subject", minWidth: 100, flex: 1 },
      {
        field: "created_at",
        headerName: "Created At",
        minWidth: 100,
        flex: 1,
        valueFormatter: (params) => getDateTime(params.value),
      },
      {
        field: "action",
        headerName: "Action",
        sortable: false,
        minWidth: 100,
        flex: 1,
        renderCell: (params) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              gap: 1,
            }}
          >
            <Button
              variant="outlined"
              color="success"
              size="small"
              sx={{
                height: "30px",
                width: "100px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                setData({
                  advertisement_id: params.id,
                  action: !params.row.is_public,
                });
                handleOpen();
              }}
            >
              <Typography sx={{ fontSize: "11px" }}>Public</Typography>
            </Button>
            <Button
              variant="outlined"
              color="error"
              size="small"
              sx={{
                height: "30px",
                width: "100px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                setData({
                  advertisement_id: params.id,
                  action: "delete",
                });
                handleOpen();
              }}
            >
              <Typography sx={{ fontSize: "11px" }}>Delete</Typography>
            </Button>
          </Box>
        ),
      },
    ],
    [setData, handleOpen]
  );

  return (
    <>
      <DataGrid
        columns={columns}
        rowCount={res?.data?.total ?? 0}
        rows={res?.data ?? []}
        loading={isLoading}
        pagination
        paginationMode="server"
        checkboxSelection
        onSelectionModelChange={handleOnSelecting}
        page={page}
        pageSize={pageSize}
        onPageChange={(v) => setPage(v)}
        onPageSizeChange={(v) => setPageSize(v)}
      />
      <ComfirmDialog
        open={open}
        handleClose={handleOpen}
        handleClickOpen={handleOpen}
        handleOnConfirm={
          data.action === "delete" ? deleteadvertisement : statuschange
        }
      />
    </>
  );
};

export default InactiveAdvertisements;
